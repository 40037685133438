body {
  .MuiButton-root {
    min-width: auto;
    letter-spacing: 0;
    font-weight: $font-bold;
    font-family: $base-font-family;
    padding: 6px 16px;
    background-color: $blue;
    color: $white;
    font-size: 13px;
    line-height: 20px;
    text-transform: capitalize;

    @include media('>=tablet') {
      padding: 6px 24px;
    }

    &:hover {
      background-color: darken($blue, 15%);
    }
  }
}

.button {
  padding: 6px 16px;
  background-color: $blue;
  color: $white;
  border-radius: 4px;
  font-size: 13px;
  line-height: 20px;
  font-family: $base-font-family;
  font-weight: $font-bold;
  display: inline-block;
  @include animate(background-color color, 0.2s);

  @include media('>=tablet') {
    padding: 6px 24px;
  }

  &.btn-sm {
    font-size: 14px;
    line-height: 17px;
    padding: 8px 16px;
  }

  &:hover {
    background-color: darken($blue, 15%);
  }

  &.table-button {
    max-width: 200px;
    width: 100%;

    &.small {
      width: auto;
    }
  }

  &.like-input {
    background-color: transparent;
    border-bottom: 1px solid $black-04;
    border-radius: 0;
  }

  &.button-light {
    background-color: $black-02;
    color: $black;

    &:hover {
      background-color: darken($black-02, 15%);
    }
  }

  &.button-damage {
    background-color: $red-05;
    color: $white;

    &:hover {
      background-color: darken($red-05, 15%);
    }
  }

  &.button-outline {
    background-color: transparent;
    border: 2px solid $blue;
  }

  &.icon-button {
    padding: 5px;

    img {
      width: 18px;
    }
  }
}


.table-button {
  cursor: pointer;

  &.link-view {
    color: $blue;
    font-size: 14px;
    font-weight: $font-bold;
    @include animate(color);

    &:hover {
      color: $white;
    }
  }
}
