.modal-body {
  background-color: #232c35;
  border-radius: 8px;
  max-width: 782px;
  width: 100%;
  min-height: 62%;
  max-height: 90%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $black-10;
  outline: none;

  @include media('<tablet') {
    width: calc(100% - 30px);
  }

  &.only-message {
    min-height: auto;
    max-width: 600px;

    .modal-content {
      position: relative;
      max-height: none;
      overflow: visible;
      top: auto;
      left: auto;
      bottom: auto;
      padding: 54px 32px;
      text-align: center;

      .icon-close {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 24px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        color: #d8dfe6;
        font-weight: $font-semi-bold;
        margin-bottom: 0;
      }
    }
  }

  &.no-scroll {
    overflow-y: auto;

    .modal-header {
      position: relative;
      top: auto;
      left: auto;
      box-shadow: none;
    }

    .modal-content {
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      max-height: none;
    }
  }
}

.modal-header {
  color: $black;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 32px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #232c35;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

  p {
    color: #d8dfe6;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: $font-semi-bold;

    .user {
      font-weight: $font-bold;
      color: $white;
    }
  }

  .icon-close {
    display: inline-block;
    color: $main-blue;
    cursor: pointer;
  }
}

.icon-close {
  display: inline-block;
  color: $main-blue;
  cursor: pointer;
}

.modal-content {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100% - 200px);
  position: absolute;
  top: 100px;
  bottom: 100px;
  width: 100%;
  padding: 0 32px 14px;

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: $black-07;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $black-02;
  }
}

.modal-footer {
  padding: 30px 32px;
  text-align: right;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #232c35;
}

.resizable-textarea {
  textarea {
    resize: vertical;
  }
}

body {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
