// Header

body {
  .MuiAppBar-positionFixed {

    @include animate(background-color);

    .MuiToolbar-root {
      max-width: 100%;
      left: 0;
      flex-wrap: wrap;
    }

    &.is-fixed {

      &::after {
        width: calc(100% + 70px);
        background-color: rgba(0,0,0,.95);
      }

      .MuiToolbar-regular {

        &.header-wrapper {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }

    &::after {
      content: '';
      display: block;
      background-color: rgba(0,0,0,.4);
      height: 100%;
      width: 0;
      position: absolute;
      left: -30px;
      top: 0;
      transform: skew(30deg);
      z-index: 1;
      transition: all .7s cubic-bezier(.42, 0, .58, 1);
    }
  }

  .MuiAppBar-colorPrimary {
    background-color: transparent;
    box-shadow: none;
  }

  .MuiToolbar-regular {
    min-height: auto;
    z-index: 9;

    &.header-wrapper {
      padding: 12px 15px;
      justify-content: space-between;
      @include animate(padding);

      @include media ('>=tablet') {
        padding: 16px 15px;
      }

      @include media ('>=desktop') {
        padding: 16px 15px;
        justify-content: flex-start;
      }

      @include media ('>=widescreen') {
        padding: 40px 60px;
      }

      @include media ('<desktop') {

        .navigation {
          order: 2;
        }

        .menu-navigation {
          order: 1;
        }

        .button-holder {
          order: 3;
        }
      }
    }
  }

  .MuiToolbar-gutters {
    padding: 0;
  }
}

.navigation {
  display: flex;
  align-items: center;

  @include media ('>=desktop') {
    margin-right: 40px;
  }

  .logo {
    max-width: 110px;
    flex-shrink: 0;

    @include media ('>=tablet') {
      max-width: 160px;
    }
  }
}

.button-holder {
  display: flex;
  align-items: center;

  @include media ('>=desktop') {
    margin: 0 0 0 auto;
  }

  .button {

    [class*="gw-icon-"] {
      @include media ('<widescreen') {
        display: none;
      }
    }
  }

  .user {
    display: flex;
    align-items: center;

    @include media ('>=desktop') {
      margin-left: 10px;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    .user-hi-text {
      font-size: 14px;
      font-weight: $font-light;

      &:not(:only-child) {
        margin-left: 10px;
      }

      .name {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 54px;
        font-weight: $font-bold;
        color: #f2f2f2;
        font-size: 13px;
        //line-height: 1;

        @include media ('>=tablet') {
          max-width: 100px;
        }
      }

      .role {
        color: #7f8b98;
        font-size: 12px;
        line-height: 1;
        font-weight: $font-semi-bold;
      }
    }
  }

  &.menu-variant {
    .button {

      @include media ('<desktop') {
        display: none;
      }
    }

    .user {
      @include media ('<desktop') {
        margin-right: 0;
      }
    }

    .user-hi-text {

      @include media ('<desktop') {
        display: none;
      }
    }
  }
}

.admin-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18222b;
  border-radius: 16px;
  padding: 10px 10px;
  margin-right: 10px;

  @include media ('>=desktop') {
    margin-right: 30px;
    min-width: 142px;
  }

  span {
    color: #f85959;
    font-size: 13px;
    line-height: 1;
    font-family: $base-font-family;
    font-weight: $font-semi-bold;
  }
}
