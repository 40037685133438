// Colors
$black: #000;
$white: #fff;
$yellow: #ffcb11;
$bg-color: #0b1620;
$main-blue: #0a7098;

  // Black
$black-01: #f2f2f2;
$black-02: #e4e7eb;
$black-04: #9aa5b1;
$black-05: #7b8794;
$black-07: #52606d;
$black-09: #323f4b;
$black-10: #232c35;

// Red
$red-02: #ffbdbd;
$red-03: #ff9b9b;
$red-05: #ef4e4e;
$red-08: #ab091e;

// Indigo
$indigo-01: #d9e8ff;
$indigo-02: #b0d0ff;
$indigo-03: #88b1fc;
$indigo-04: #5e8aee;
$indigo-05: #3a66db;
$indigo-06: #2251cc;
$indigo-07: #1d3dbf;
$indigo-08: #132dad;
$indigo-09: #0b1d96;
$indigo-10: #061178;

// Cyan
$cyan-01: #e1fcf8;
$cyan-02: #c1fef6;
$cyan-03: #92fdf2;
$cyan-04: #62f4eb;
$cyan-05: #3ae7e1;
$cyan-06: #1cd4d4;
$cyan-07: #0fb5ba;
$cyan-08: #099aa4;
$cyan-09: #07818f;
$cyan-10: #05606e;

$blue: #25aef6;

//$danger: #cd0000;
$info: #41a0fc;
$warning: #ebbf4e;
$success: #5acb89;
$danger: #f47265;

// Typography
$base-font-family: 'Open Sans', 'Helvetica', sans-serif;

// Headings FontSize
$h1-font-size: 96px !default;
$h2-font-size: 60px !default;
$h3-font-size: 48px !default;
$h4-font-size: 32px !default;
$h5-font-size: 24px !default;
$h6-font-size: 20px !default;

// Font Weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-black: 900;

// Body
$base-text-color: $black !default;
$base-background-color: $bg-color;
$font-size-base: 16px !default;
$line-height-base: 26px !default;
$base-font-family: $base-font-family !default;
$base-min-width: 375px;

// Links
$base-link-color: $blue;

// Animations
$animation-speed: 0.3s;
