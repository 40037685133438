.login-form {
  background-color: $black-10;
  padding: 24px 12px;
  border-radius: 8px;
  width: calc(100% - 30px);
  margin: 0 auto;

  @include media('>=tablet') {
    width: 584px;
    padding: 34px 25px;
  }

  @include media('>=desktop') {
    padding: 64px 60px;
  }

  .form-row {

    .MuiOutlinedInput-input {
      font-size: 16px;
    }

    .MuiButton-root {
      padding: 18px 24px;
    }
  }
}

.form-row {

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  .MuiFormControl-root {
    width: 100%;

    label + .MuiInput-formControl {
      margin-top: 10px;
      margin-bottom: 0;
    }

    &.error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $danger;
      }
    }
  }

  .form-label {
    margin-bottom: 10px;
    margin-left: 16px;
    display: block;
    line-height: 1;
    font-size: 16px;
    font-weight: $font-semi-bold;
    color: $black-01;

    &.sm {
      font-size: 14px;
      color: #f2f2f2;
    }
  }

  &.button-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.center {
      justify-content: center;
    }

    &.space-around {
      justify-content: space-around;
    }

    .button {
      padding: 10px 24px;
      max-width: 240px;
      width: 100%;
    }
  }

  &.feedback-row {

    .feedback-text {
      margin-left: 16px;
      font-size: 13px;
      line-height: 16px;
      opacity: 0.7;
      color: #d8dfe6;
    }
  }
}

.feedback-section {
  border-bottom: 2px solid #2a343e;
  padding: 24px 0;

  &:last-child {
    border: none;
  }
}

.login-holder {

  .login-heading {
    color: $black-01;
    text-align: center;
    margin-bottom: 40px;
  }
}
