body {

  .dropdown {

    .MuiSvgIcon-root {
      display: none;
    }

    &::after {
      content: "\e903";
      font-family: 'icomoon' !important;
      color: $main-blue;
      font-size: 10px;
      position: absolute;
      right: 10px;
    }
  }

  .MuiList-root {
    background-color: $main-blue;

    .MuiMenuItem-root {
      font-family: $base-font-family;
      font-size: 13px;
      letter-spacing: 0;
      color: $white;
    }
  }
}

.dropdown {

  &.fullwidth {
    width: 100%;
  }
}