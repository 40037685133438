body {
  .MuiTypography-colorPrimary {
    color: $white;
  }

  .MuiLink-root {
    font-size: 16px;
    line-height: 19px;
  }
}

.subheading {
  color: $red-05;
  font-size: 20px;
  line-height: 26px;
  font-weight: $font-medium;
  font-family: $base-font-family;
  margin-bottom: 12px;
  display: inline-block;

  &.sm {
    font-size: 16px;
    line-height: 19px;
  }
}

.red-05 {
  color: $red-05;
}

.text-white {
  color: $white !important;
}
