.badge {
  color: $white;
  background: $blue;
  padding: 5px 10px;
  border-radius: 20px;

  i {
    color: $white;
  }
}
