// Typography

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $base-font-family;
  font-weight: $font-bold;
  line-height: 1;
  margin: 0 0 12px;
  color: $white;
}

h1,
.h1 {
  font-size: 34px;

  @include media('>=desktop') {
    font-size: $h1-font-size;
  }
}

h2,
.h2 {
  font-size: 34px;

  @include media('>=widescreen') {
    font-size: $h2-font-size;
  }
}

h3,
.h3 {
  font-size: 34px;

  @include media('>=widescreen') {
    font-size: $h3-font-size;
  }
}

h4,
.h4 {

  @include media('>=desktop') {
    font-size: $h4-font-size;
  }
}

h5,
.h5 {
  font-size: 20px;

  @include media('>=desktop') {
    font-size: $h5-font-size;
  }
}

h6,
.h6 {
  font-size: $h6-font-size;
}

.subtitle {
  font-size: 16px;
  line-height: 1;
  font-weight: $font-bold;
  display: block;

  &-light {
    font-size: 14px;
    line-height: 22px;
  }
}

.caption {
  font-size: 12px;
  line-height: 1.5;
  font-weight: $font-medium;
}

.overline {
  font-size: 10px;
  line-height: 16px;
  font-weight: $font-semi-bold;
  text-transform: uppercase;
}

p {
  margin: 0 0 1em;
  color: $black-04;
}

a {
  color: $base-link-color;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.font-light {
  font-weight: $font-light;
}

.font-regular {
  font-weight: $font-regular;
}

.font-medium {
  font-weight: $font-medium;
}

.font-semi-bold {
  font-weight: $font-semi-bold;
}

.font-bold {
  font-weight: $font-bold;
}

.font-black {
  font-weight: $font-black;
}

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-underline {
  text-decoration: underline !important;
}
