body {
  .MuiAvatar-root {
    width: 28px;
    height: 28px;
    font-family: $base-font-family;
    border: 2px solid $white;
    font-size: 16px;
    transition: all 0.2s;
    cursor: pointer;

    @include media('>=tablet') {
      width: 50px;
      height: 50px;
    }

    &:hover {
      border-color: $blue;
    }
  }
}
