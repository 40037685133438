.all-users-list {
  li {
    color: $white;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 15px;
  }
}

.admin-badge {
  display: inline-block;
  background-color: $blue;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 10px;
  font-style: italic;
  line-height: 1;
  margin-right: 10px;
}