.fake-checkbox {
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    &:checked {

      &+label {
        &::before {
          background-color: $main-blue;
        }
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  label {
    position: relative;
    padding-left: 24px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    font-family: $base-font-family;

    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border: 1px solid $main-blue;
      background-color: transparent;
      border-radius: 3px;
      position: absolute;
      top: 4px;
      left: 0;
    }

    &::after {
      content: '';
      display: block;
      width: 9px;
      height: 5px;
      border-left: 2px solid #262f38;
      border-bottom: 2px solid #262f38;
      position: absolute;
      top: 10px;
      left: 5px;
      transform: rotate(-45deg);
      opacity: 0;
      visibility: hidden;
      @include animate(opacity visibility);
    }
  }
}