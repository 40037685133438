.range {
  width: 100%;
  padding: 0 15px;
}

body {
  .MuiSlider-root {
    color: $white;
  }

  .MuiSlider-markLabel {
    font-family: $base-font-family;
    font-size: 12px;
    line-height: 1;
    color: $white;
  }

  .PrivateValueLabel-label-13 {
    color: $indigo-08;
  }
}