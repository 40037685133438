body {
  .MuiPickersDay-root {
    color: $white;

    &.Mui-selected {
      background-color: $blue;

      &:hover {
        background-color: darken($blue, 25%);
      }
    }
  }

  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: rgba($blue, .5);
  }

  .MuiPickersModalDialog-dialogRoot {
    max-width: 320px;
    background-color: #232c35;
  }

  .MuiPickersPopper-paper {
    background-color: #232c35;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.5);
  }

  .MuiPickersToolbar-root {
    background-color: $blue;
    padding: 0 10px 10px;
  }

  .MuiTypography-overline {
    font-family: $base-font-family;
    font-weight: $font-semi-bold;
    letter-spacing: 0;
  }

  .MuiPickersCalendar-weekDayLabel {
    color: $white;
  }

  .MuiDialogActions-root {
    .MuiButton-root {
      color: $white;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}