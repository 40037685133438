.vacancy-box {
  display: flex;
  border: 1px solid rgba($white, .2);
  border-radius: 6px;
  padding: 20px 24px;
  max-width: 800px;
  margin: 0 auto 20px;
  transition: all .2s;

  &:hover {
    border-color: $white;
  }

  @include media ('<tablet') {
    flex-wrap: wrap;
  }

  .learn-more {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 20px;

    @include media ('<tablet') {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
      justify-content: flex-end;
    }
  }
}

.vacancy-image {
  max-width: 40px;
  margin-right: 20px;
  flex-shrink: 0;
}

.vacancy-description {
  flex-grow: 1;

  @include media ('<tablet') {
    width: 100%;
    margin-top: 20px;
  }

  .vacancy-title {
    margin-bottom: 6px;
  }

  .vacancy-short-description {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
  }
}
