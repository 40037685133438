$breakpoints: (
        'xs-phone': 400px,
        'phone': 520px,
        'tablet': 768px,
        'desktop': 1024px,
        'widescreen': 1200px,
        'largescreen': 1600px,
);

// Vendors
@import "vendors/include-media";
@import "vendors/normalize";
@import "vendors/icomoon";

// Base
@import "base/variables";
@import "base/helpers";
@import "base/mixins";
@import "base/reset";
@import "base/typography";

// Layout
@import "layout/header";
@import "layout/common";
@import "layout/admin-footer";
@import "layout/sidebar";

// General
@import "general/text";
@import "general/flex";
@import "general/spacing";

// Components
@import "components/avatar";
@import "components/buttons";
@import "components/checkbox";
@import "components/datepicker";
@import "components/dropdown";
@import "components/filters";
@import "components/input";
@import "components/login";
@import "components/modals";
@import "components/navigation";
@import "components/radio";
@import "components/range";
@import "components/search";
@import "components/table";
@import "components/vacancy-box";
@import "components/pagination";
@import "components/badge";

// Pages
@import "pages/vacancies";
@import "pages/all-users";

* {
  //transition: all, .2s;
}

body {
  margin: 0;
  width: 100%;
  position: relative;
  min-width: $base-min-width;
  background-color: $base-background-color;
  font-family: $base-font-family;
}

#wrapper {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

.container {
  max-width: 1130px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

::selection {
  background-color: $indigo-05;
  color: $white;
}