.sidebar-list {
  width: 100%;

  li {

    .MuiLink-root {
      transition: all .2s;
      font-size: 26px;
      line-height: 1;
      font-family: $base-font-family;

      &:hover,
      &:focus,
      &:active,
      &.active {
        padding-left: 20px;
        transform: skew(-15deg);
      }

      &.active {
        color: $blue;
      }
    }
  }
}

body {
  .MuiDrawer-paper {
    background-color: #262f38;
    width: 364px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px 24px;

    .burger-btn {
      display: block;
      margin: 0 0 32px auto;
    }

    &.content-top {
      justify-content: flex-start;
    }
  }

  .MuiDrawer-root {
    &.content-top {

      .MuiDrawer-paper {
        justify-content: flex-start;
      }
    }
  }
}