.filters-box {
  width: 100%;

  .form-row {
    margin-bottom: 20px;

    &.center {
      text-align: center;
    }
  }
}

.filter-heading {
  color: $black;
  font-style: italic;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: $font-semi-bold;
  display: inline-block;
}