.menu-navigation {

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('<desktop') {
      display: none;
    }

    li {
      margin: 0 10px;

      .MuiLink-root {
        font-size: 14px;
        line-height: 1;

        &:hover {
          color: $blue;
        }

        &.active {
          color: $blue;
        }
      }
    }
  }
}

.burger-btn {
  z-index: 9999;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media('>=desktop') {
    display: none;
  }

  span {
    display: block;
    width: 18px;
    height: 2px;
    margin-bottom: 3px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: $white;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:first-child {
      transform-origin: 0 0;
    }

    &:nth-last-child(2) {
      transform-origin: 0 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(2px, -2px);
      background: $white;

      &:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.1, 0.1);
      }

      &:nth-last-child(3) {
        transform: rotate(-45deg) translate(-12px, 16px);
      }
    }
  }
}