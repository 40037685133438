body {

  .MuiFormControl-root {
    &.search-input {
      .MuiOutlinedInput-notchedOutline {
        border: solid 1px #232c35;
      }

      .MuiInputBase-input {
        padding: 8px 24px 8px 40px;
        position: relative;
        background-image: url(../../images/icon-search.svg);
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 12px 50%;
      }
    }

    &.input-sm {
      .MuiOutlinedInput-input {
        font-weight: $font-semi-bold;

        @include placeholder {
          color: #2e3c49;
          opacity: 1;
        }
      }

      .MuiInputBase-input {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    &.date-range-input {
      &:not(:last-child) {
        margin-right: 10px;
      }
      .MuiFormHelperText-root {
        display: none;
      }

      .MuiInputLabel-outlined {
        color: $white;
        font-size: 14px;
        transform: translate(14px, 15px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(14px, -3px) scale(0.75);
        }
      }
    }

    &.error-input {
      .MuiOutlinedInput-notchedOutline {
        border-color: #f85959;
      }
    }
  }

  .MuiInputBase-root {
    font-size: 14px;
    line-height: 1;
  }

  .MuiInputBase-input {
    padding: 12px 24px;
  }

  .MuiInput-underline {

    &::after {
      border-color: $blue;
    }
    &::before {
      border-color: $black-04;
    }
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: solid 1px $main-blue;
  }

  .MuiOutlinedInput-input {
    color: $white;
    font-family: $base-font-family;
    font-size: 14px;
    font-weight: $font-bold;

    @include placeholder {
      color: #2e3c49;
      opacity: 1;
    }
  }

  .MuiOutlinedInput-root {
    background-color: transparent;
    border-radius: 4px;

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $main-blue;
        border-width: 1px;
      }
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $white;
      }
    }
  }
}

.subtext {
  font-size: 12px;
  line-height: 16px;
  font-weight: $font-semi-bold;
  margin-bottom: 0;
  margin-top: 5px;
  margin-left: 10px;

  &.error {
    color: #f85959;
  }
}