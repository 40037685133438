body {
  .MuiTableContainer-root {
    background-color: #232c35;
  }

  .MuiTableCell-root {
    border-bottom: solid 1px #2a343e;

  }

  .MuiTableCell-head {
    background-color: #18222b;
    color: #4f5a66;
    font-size: 16px;
    font-weight: $font-semi-bold;
    font-family: $base-font-family;
    border-bottom: none;
  }

  .MuiTableCell-body {
    color: #d8dfe6;
    font-size: 14px;
    font-weight: $font-semi-bold;

    .button {
      margin-bottom: 0;
    }
  }

  .MuiTableRow-root {
    &:not(:only-child) {
      &:last-child {
        .MuiTableCell-root {
          border: none;
        }
      }
    }
  }

  .MuiTableCell-root {
    font-family: $base-font-family;
  }

  .MuiTableSortLabel-root {
    &.MuiTableSortLabel-active {
      color: $blue;
    }
  }

  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: $blue;
  }

  .MuiTablePagination-root {
    font-family: $base-font-family;

    .MuiSelect-icon {
      color: rgba($white, .54);
    }

    .MuiSelect-select {
      padding: 10px 24px 10px 10px;
      color: $blue;
    }

    .MuiIconButton-root.Mui-disabled {
      .MuiSvgIcon-root {
        color: rgba($white, .4);
      }
    }

    .MuiSvgIcon-root {
      color: $white;
    }
  }

  .MuiTablePagination-caption {
    color: $white;
    font-family: $base-font-family;
  }
}


.table-actions {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-end;

  @include media ('<desktop') {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .search-box,
  .button {
    margin-left: 16px;

    @include media ('<desktop') {
      margin-left: 0;
      margin-top: 20px;
      margin-right: 16px;
      flex-shrink: 0;
    }
  }

  .search-box {
    @include media ('<desktop') {
      max-width: 100%;
      margin-right: 0;
    }
  }
}

.table-applied-filters-row {
  display: flex;
  margin: 10px 0;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;

  .MuiTable-root {
    min-width: 900px;
  }
}

.label-with-sort-icon {
  display: flex;
  align-items: center;
}

.sort-icon {
  font-size: $h5-font-size;
  width: 25px;
  height: 25px;

  &-asc {
    transform: rotate(-90deg);
  }

  &-desc {
    transform: rotate(90deg);
  }
}
