ul,
ol {
  @extend %listreset;
}

.link {
  cursor: pointer;
}

sup {
  font-size: 50%;
}

h2 {

  sup {
    top: -14px;
    font-size: 35%;
  }
}

.full-height-holder {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  .full-height-inner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;

    &>section,
    &>div {
      width: 100%;
    }
  }

  &.with-header {
    .full-height-inner {
      padding-top: 52px;
      padding-bottom: 52px;

      @include media ('>=tablet') {
        padding-top: 72px;
        padding-bottom: 72px;
      }

      @include media ('>=widescreen') {
        padding-top: 130px;
        padding-bottom: 130px;
      }
    }
  }
}

#main {
  padding-top: 130px;

  &.without-header {
    padding-top: 0;
  }

  .full-height-holder {
    &.with-header {
      .full-height-inner {
        padding: 0;
        min-height: calc(100vh - 130px);
      }
    }
  }
}

.table-section {
  padding: 0 0 30px;

  .table-header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media ('<desktop') {
      flex-wrap: wrap;
    }
  }

  .table-heading {
    margin-bottom: 0;
  }

}

.table-heading {
  font-weight: $font-bold;
  color: #f2f2f2;
}

.footer-to-bottom {
  display: flex;
  flex-direction: column;

  #main {
    flex-grow: 1;
  }
}

.not-found-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &>img {
    max-width: 443px;
    display: none;

    @include media ('>=tablet') {
      display: block;
    }
  }

  .not-found-description {
    text-align: center;
    padding: 0 15px;
  }

  h4 {
    margin-bottom: 8px;
  }

  .error-code {
    font-size: 200px;
    color: #0b6fa4;
    letter-spacing: 10px;
    text-shadow: 0 2px 16px rgba(0, 0, 0, 0.3);
    margin-bottom: 32px;
  }

  p {
    font-size: 18px;
    color: #f2f2f2;
    font-weight: $font-semi-bold;
    margin-bottom: 24px;
  }
}

.all-feedbacks-box {
  padding: 32px 48px;
  border-radius: 8px;
  background-color: #232c35;
  margin-bottom: 32px;

  .feedbacks-year {
    color: $white;
    font-size: 18px;
    font-weight: $font-bold;
    margin-bottom: 12px;
  }

  .all-feedbacks-list {
    column-count: 1;

    @include media('>=tablet') {
      column-count: 3;
    }

    @include media('>=desktop') {
      column-count: 4;
    }

    li {

      span {
        display: inline-block;
        padding: 12px 0;
      }
    }
  }
}

.all-feedbacks-list {

  li {

    span {
      font-size: 14px;
      line-height: 1;
      color: $blue;
      font-weight: $font-bold;
    }
  }
}

.back-link {
  display: inline-flex;
  align-items: center;
  color: $black-01;
  cursor: pointer;
  font-size: 20px;
  font-weight: $font-bold;
  margin-bottom: 16px;

  &:hover {
    [class*='icon-'] {
      margin-right: 10px;
    }
  }

  [class*='icon-'] {
    color: $blue;
    margin-right: 20px;
    font-size: 16px;
    transition: all, .2s;
  }
}